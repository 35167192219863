import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import GroupView from "@/views/GroupView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/c/:code",
    name: "home",
    component: HomeView,
  },
  {
    path: "/g/:code",
    name: "group",
    component: GroupView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
export default router;
